
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { PurchaseView } from '@/components/forms/view/PurchaseView'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import logOut from '@/components/LogOut.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixDate, fixPrice, parseToNumber, sendNotification, stringifySafe, updateNestedObject } from '@/utils/general'
  import { Auto, City } from '@/entities/public'
  import { plainToInstance } from 'class-transformer'
  import { PurchaseOrder } from '@/entities/purchase'
  import { AcquisitionType, Expense, ProcessExpense } from '@/entities/settings'
  import { Component as Components } from '@/entities/vehicle'
  import { Person } from '@/entities/persons'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import dayjs from 'dayjs'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import { DateFormatter } from '@/utils/date/DateFormatter'
  import { Payment, PaymentOrder, PaymentOrderItem, PaymentRecipient, PaymentType } from '@/entities/finance'
  import { CavCurrOwners } from '@/entities/files/CavCurrOwners'
  import { mapActions } from 'vuex'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import { Lead } from '@/entities/crm'

@Component({
  components: {
    GRadioButton,
    GFormSlot,
    GCostField,
    logOut,
    GLoading,
    GAlert,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
  methods: {
    ...mapActions('resources/form', ['createDirectPurchase']),
  },
})
  export default class PurchaseStockForm extends PurchaseView {
  declare $refs: {
    form: HTMLFormElement
    fieldAuto: LinkedAuto
    fieldSigners: LinkedPerson
    fieldClient: LinkedPerson
  };

  createDirectPurchase!: (payload: any) => Promise<Record<string, unknown>>;

  title = ''
  purchaseOrder = plainToInstance(PurchaseOrder, {})
  disabledPurchase = false
  displayConsignment = false
  maxMileageConsignment = null
  idProcessPurchase = null
  idProcessSaleOrder = null
  idProcessNegotiation = null
  idProcessInspection = null
  cities: City[] = []
  plants: City[] = []
  insurances: Person[] = []
  components: Components[] = []
  acquisitionTypes: AcquisitionType[] = []
  documentFilesParameters = []
  internalServiceAmount = null
  displayInternalServiceAmount = false
  fileInfo = null
  panels = [0, 1, 2, 3]
  maxMileage = null
  metadata = null
  showDetail = false
  signerError = ''
  ownerError = ''

  section = {
    interviews: false,
    documents: false,
    payment: false,
    contract: false,
  }

  negotiationAlert = {
    flag: false,
    title: '',
    subtitle: '',
  }

  formData = {
    whoSigns: null,
    channel: null,
    lastOrder: null,
    expenses: null,
    documentType: null,
    auto: null,
    contract: [],
    linkContract: null,
    mileage: null,
    price: null,
    acquisitionType: null,
    notarizedAuthorization: [],
    fileLegalReport: [],
    linkLegalReport: null,
    client: null,
    owners: null,
    signers: null,
    cav: [],
    penalties: [],
    inspection: {
      soap: {
        photo: null,
        date: null,
        selection: null,
        cost: null,
      },
      technicalReview: {
        photo: null,
        date: null,
        selection: null,
        cost: null,
      },
      circulationPermit: {
        selection: null,
        date: null,
        amount: null,
        photo: null,
        cost: null,
      },
    },
    stock: null,
    isRenewal: null,
  }

  fields = {
    stock: {
      properties: {
        label: 'Stock',
        itemText: 'formName',
        itemValue: 'id',
        returnObject: true,
      },
      rules: this.fieldRequired,
      items: [],
    },
    channel: {
      properties: {},
      items: [],
      rules: [],
    },
    expenses: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    linkContract: {
      properties: {
        required: false,
      },
    },
    linkLegalReport: {
      properties: {},
    },
    notarizedAuthorization: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    auto: {
      properties: {
        required: true,
        properties: {
          label: 'Vehículo',
          rules: this.fieldRequired,
          itemText: 'formName',
          itemValue: 'id',
          required: true,
          returnObject: true,
        },
      },
      items: [],
      rules: this.fieldRequired,
    },
    price: {
      properties: {},
      rules: this.fieldRequired,
    },
    cav: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
        appendOuterIcon: '',
      },
    },
    penalties: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
        appendOuterIcon: '',
      },
    },
    legalReport: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
        appendOuterIcon: '',
      },
    },
    inspectionPhoto: {
      properties: {
        label: '',
        accept: '',
        multiple: false,
        name: '',
        appendOuterIcon: '',
      },
    },
    client: {
      properties: {},
      items: [],
      rules: [],
    },
    owners: {
      properties: {},
      items: [],
      rules: [],
    },
    signers: {
      properties: {},
      items: [],
      rules: [],
    },
    documentType: {
      properties: {},
      rules: null,
      items: [],
    },
    contract: {
      properties: {
        label: '',
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
        appendOuterIcon: '',
      },
    },
  }

  purchaseAlert = {
    open: false,
    title: '¿Está seguro que desea eliminar el gasto?',
    item: null,
  }

  async mounted () {
    await this.setMetadata()

    const { metadata, title } = this

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, false)
    }

    this.setTheBackup()
    await this.setDetails()
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) return

    const representative = 'signer' in backup && backup.signer

    if ('purchaseForm' in backup && backup.purchaseForm) {
      this.formData = backup.purchaseForm
    }
    if ('auto' in backup && backup.auto) {
      this.formData.auto = backup.auto
    }

    if ('client' in backup && backup.client) {
      this.formData.client = backup.client
    }

    if ('panel' in backup && backup.panel) {
      this.panels = backup.panel
    }

    if (representative) {
      const signerNotExist = !this.formData.signers?.find(signer => signer.id === representative.id)
      if (signerNotExist) {
        if (this.formData.signers?.length) {
          this.formData.signers = [...this.formData.signers, representative]
        } else {
          this.formData.signers = [representative]
        }
        this.fields.signers.items.push(representative)
      }
    }

    this.setExpense(backup)
    this.purchaseOrder.paymentOrder = this.formData.expenses
  }

  setValueExpense (isExpenseForm, isPurchaseForm) {
    if (!isExpenseForm?.expense && isPurchaseForm?.expenses) {
      this.formData.lastOrder = isPurchaseForm?.expenses
      this.formData.expenses = isPurchaseForm?.expenses
      return true
    }

    if (!isExpenseForm?.expense && !isPurchaseForm?.expenses) {
      return true
    }
  }

  setInstancePayment (isExpenseForm) {
    return plainToInstance(Payment, {
      amount: isExpenseForm?.expense,
      comment: isExpenseForm?.comment,
      paymentBackup: isExpenseForm?.file,
      type: plainToInstance(PaymentType, {
        id: isExpenseForm?.paymentType?.id,
        name: isExpenseForm?.paymentType?.name,
        description: isExpenseForm?.paymentType?.description,
      }),
    })
  }

  get isCavInValid () {
    const { purchaseOrder, formData: { acquisitionType } } = this

    const invalidCav = purchaseOrder.validations?.some(validation => {
      const fieldName = validation.name.split(':')[0].trim()

      return (
        ['Patente', 'Año', 'Marca', 'Combustible'].includes(fieldName) &&
        validation.value === false
      )
    }) || false

    if (invalidCav && acquisitionType?.name === 'purchase') {
      return 'Cav inválido, no puede continuar la compra'
    } else if (invalidCav && acquisitionType?.name === 'consignment') {
      return 'Cav inválido, no puede continuar la consignación'
    }

    return invalidCav ? 'Cav inválido verifica antes de continuar' : ''
  }

  async openAlertNegotiation () {
    const invalidCav = this.isCavInValid?.length
    const client = this.$refs.fieldClient && !this.$refs.fieldClient.$refs.form.validate()
    const signers = this.$refs.fieldSigners && !this.$refs.fieldSigners?.$refs.form.validate() && this.formData.whoSigns === false
    const autoForm = this.$refs.fieldAuto && !this.$refs.fieldAuto?.$refs.form.validate()
    const errorsPresent = Boolean(this.expenseError?.length) || Boolean(this.signerError?.length && this.formData.whoSigns === false) || Boolean(this.ownerError?.length)
    const signerEmpty = !this.signers?.length

    if (invalidCav || !this.$refs.form.validate() || autoForm || client || signers || errorsPresent || signerEmpty) {
      await this.setFieldError()
      return
    }

    this.negotiationAlert.title = `Está creando una ${this.formData.acquisitionType?.description}`
    this.negotiationAlert.subtitle = '¿Estás seguro que deseas continuar?'
    this.negotiationAlert.flag = true
  }

  setExpense (backup) {
    const isExpenseForm = 'expenseForm' in backup && backup.expenseForm
    const isPurchaseForm = 'purchaseForm' in backup && backup.purchaseForm

    if (isExpenseForm?.payment?.id) return

    if (this.setValueExpense(isExpenseForm, isPurchaseForm)) return

    const recipient = plainToInstance(PaymentRecipient, {})
    recipient.person = isExpenseForm?.beneficiary
    const payment = this.setInstancePayment(isExpenseForm)
    payment.recipientAccount = isExpenseForm?.account
    recipient.payments = [payment]
    if (!isPurchaseForm?.expenses) {
      const order = plainToInstance(PaymentOrder, {
        items: [plainToInstance(PaymentOrderItem, {
          amount: parseToNumber(this.formData.price),
          processExpense: plainToInstance(ProcessExpense, {
            expense: plainToInstance(Expense, {
              description: isExpenseForm.expenseType.description,
              name: isExpenseForm.expenseType.name,
            }),
          }),
        })],
      })

      order.items[0].recipients = [recipient]
      this.formData.lastOrder = order
      this.formData.expenses = order
    } else {
      const lastOrder = isPurchaseForm?.expenses
      const expenseType = isExpenseForm?.expenseType

      const pos = lastOrder?.items?.findIndex(item => item.processExpense.expense.name === expenseType.name)
      if (pos > 0) {
        lastOrder.items[pos].recipients.push(recipient)
      } else {
        const orderItem = plainToInstance(PaymentOrderItem, {
          amount: parseToNumber(this.formData.price),
          processExpense: plainToInstance(ProcessExpense, {
            expense: plainToInstance(Expense, {
              description: isExpenseForm.expenseType.description,
              name: isExpenseForm.expenseType.name,
            }),
          }),
        })
        orderItem.recipients = [recipient]
        lastOrder.items.push(orderItem)
      }

      this.setFormDataExpense(lastOrder)
    }
  }

  setFormDataExpense (lastOrder) {
    this.formData.lastOrder = lastOrder
    this.formData.expenses = lastOrder
  }

  async setMetadata () {
    const { metadata } = this.getForm('PurchaseOrder', 'staff_purchase_stock')
    const { fields, form } = metadata as Form
    this.metadata = metadata
    this.title = form.title
    Object.assign(this.fields.auto.properties, fields.auto?.properties)
    this.fields.price.properties = fields.price.properties
    this.fields.owners.properties = fields.owners.properties
    this.fields.client.properties = fields.client.properties
    this.fields.signers.properties = fields.signers.properties
    this.fields.expenses.properties = fields.expenses.properties

    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: {
        table_name: { _eq: 'purchase_order' },
      },
    }))[0]

    this.idProcessPurchase = process?.id
    this.displayConsignment = process?.config?.displayConsignment

    await this.getListValues()
    const documentation = (await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Documentación' } },
    }))[0]
    this.components = documentation?.components

    const processInspection = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })

    this.idProcessInspection = processInspection?.[0]?.id
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'purchase_order' } } },
    })
    this.setProperties(fileInfo, 'notarized_authorization', 'notarizedAuthorization')
    await this.setDataInspection()
    await this.setFilesData()
    await this.setMetadataSaleOrder()
    await this.setAppraisal()
  }

  async getStock () {
    this.fields.stock.items = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: {
        stock_detail: {
          auto: {
            process_status: {
              status: {
                name: {
                  _in: [
                    'available',
                  ],
                },
              },
            },
          },
        },
      },
      force: true,
    })
  }

  async setAppraisal () {
    const processAppraisal = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: {
        table_name: { _eq: 'appraisal' },
      },
    }))[0]

    if (this.displayConsignment) {
      this.maxMileageConsignment = processAppraisal?.config?.settings?.maxMileageConsignment
    }
    this.maxMileage = processAppraisal?.config?.settings?.maxMileageAllowed
  }

  async setMetadataSaleOrder () {
    const { metadata } = this.getForm('SaleOrder', 'staff_sale_order')
    const { fields } = metadata as Form

    this.fields.linkLegalReport.properties = fields.linkLegalReport.properties
    this.fields.linkContract.properties = fields.linkContract.properties
    this.fields.documentType.properties = fields.documentType.properties
    const processSale = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: {
        table_name: { _eq: 'sale_order' },
      },
    }))[0]

    this.idProcessSaleOrder = processSale?.id

    const fileInfoSale = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'sale_order' } } },
    })

    this.documentFilesParameters = fileInfoSale

    this.setProperties(fileInfoSale, 'legal_report', 'legalReport')

    this.fields.documentType.items = await this.fetchData({
      query: { name: 'find', model: 'SupportDocumentType' },
    })
    await this.getStock()
  }

  async setDataInspection () {
    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: {},
    })

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { company_type: { name: { _eq: `technical_review_plant` } } },
      limit: 100,
    })

    const companyType = await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'insurance_carrier' } },
    })
    this.insurances = companyType?.[0]?.persons
  }

  async setFilesData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
    })

    this.idProcessNegotiation = process[0].id
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'negotiation' } } },
    })

    this.setProperties(fileInfo, 'cav', 'cav')
    this.setProperties(fileInfo, 'penalty_certificate', 'penalties')

    const fileInfoInspection = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'inspection_inspected_component' } } },
    })

    this.setProperties(fileInfoInspection, 'photo_inspection', 'inspectionPhoto')
  }

  get bindAuto () {
    const { fields: { auto }, disabledPurchase } = this

    if (!auto && (disabledPurchase)) return {}

    return { ...auto.properties, items: auto.items }
  }

  get isOverPrice () {
    const { formData: { price }, totalExpenses } = this

    if (!totalExpenses) return false

    return parseToNumber(totalExpenses) > parseToNumber(price)
  }

  get totalExpenses () {
    const { formData: { expenses, price } } = this

    if (!expenses?.payments?.length || !price) return 0

    return expenses.payments.reduce((acc, payment) => acc + parseToNumber(payment?.amount), 0)
  }

  get isExpenseFull () {
    const { formData: { price }, totalExpenses } = this

    return parseToNumber(totalExpenses) < parseToNumber(price)
  }

  get expenseError () {
    const { formData: { price }, isExpenseFull, totalExpenses, isOverPrice } = this

    if (isOverPrice) return 'El gasto no puede ser mayor al monto de la compra'

    return !isExpenseFull ? '' : `Se debe cubrir el monto de compra ${fixPrice(parseToNumber(price) - parseToNumber(totalExpenses))}`
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  get maxMileageConfig () {
    const { maxMileage, maxMileageConsignment, formData: { acquisitionType } } = this

    if (!maxMileage && !maxMileageConsignment) return []
    const max = acquisitionType?.name === 'purchase' ? maxMileage : maxMileageConsignment
    return [v => v <= max || `No se permiten vehículos con más de ${max} kms. para ${acquisitionType?.description}`]
  }

  get priceError () {
    return ''
  }

  get soap () {
    return this.findComponentBySlug(this.components, 'soap')
  }

  get technicalReview () {
    return this.findComponentBySlug(this.components, 'technical_review')
  }

  get circulationPermit () {
    return this.findComponentBySlug(this.components, 'circulation_permit')
  }

  get displayAcquisitionTypes () {
    const { acquisitionTypes } = this

    return acquisitionTypes.length !== 1
  }

  get expirationPeriodSoap () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.inspection.soap.date)
  }

  get expirationPeriodTechnicalReview () {
    const { formData } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.inspection.technicalReview.date)
  }

  get needNotarizedAuthorization () {
    const { formData: { owners, signers, whoSigns } } = this

    if (whoSigns) {
      this.formData.notarizedAuthorization = []
      this.formData.signers = []
      return false
    }

    if (!signers?.length) return false

    return signers.some(signer => !owners?.some(owner => signer?.id === owner?.id))
  }

  saveBackup () {
    const { backup, formData } = this
    if (backup) {
      backup.purchaseForm = { ...formData }
      if (!backup?.auto && formData.auto instanceof Auto) {
        backup.auto = formData.auto
      }
      this.setBackup(backup)
    } else {
      const auto = formData.auto instanceof Auto ? formData.auto : null
      this.setBackup({ purchaseForm: { ...formData }, auto, panel: this.panels })
    }
  }

  async removeExpense (item) {
    for (let i = 0; i < this.formData.expenses.items.length; i++) {
      const recipients = this.formData.expenses?.items[i].recipients
      const pos = recipients.findIndex(recipient => this.findLocalPos(recipient, {
        beneficiary: item.person,
        paymentType: item.paymentType,
        account: item.account,
        type: item.type,
        amount: item.amount,
      }))
      if (pos !== -1) {
        const recipient = this.formData.expenses.items[i].recipients[pos]
        const itemType = this.formData.expenses.items[i]

        const posPayment = recipient?.payments?.findIndex(_ => this.findLocalPayment(_, recipient.person, itemType, item))

        if (posPayment !== -1) {
          if (this.formData.expenses.items[i].recipients?.length > 1) {
            this.purchaseOrder.paymentOrder.items[i].recipients[pos].payments.splice(posPayment, 1)
            this.formData.expenses.items[i].recipients[pos]?.payments.splice(posPayment, 1)
          } else {
            this.purchaseOrder.paymentOrder.items.splice(i, 1)
            this.formData.expenses.items.splice(i, 1)
          }
        }
      }
      if (!this.formData.lastOrder?.items?.length) {
        this.formData.lastOrder = null
      }
    }
    this.purchaseAlert.open = false
    await this.setDetails()
  }

  async openAuto () {
    this.saveBackup()
    await this.$router.push({
      name: 'generic-auto',
      params: { model: 'Appraisal', uid: 'create', id: null },
    }).catch(() => {
    })
  }

  confirmRemoveExpense (item) {
    this.purchaseAlert.open = true
    this.purchaseAlert.item = item
  }

  async goToOrder (item = null) {
    if (this.backup) {
      this.backup.expenseForm = null
    }
    this.saveBackup()
    const id = 'create'

    await this.$router.push({
      name: 'generic-expenses',
      params: {
        parentModel: 'PurchaseOrder',
        parentId: id,
        uid: 'create',
      },
    })
  }

  async clearAuto () {
    const { backup } = this
    if (backup && 'auto' in backup && backup.auto) {
      this.backup.auto = null
    }
    this.fields.auto.items = []
    this.formData.auto = null

    await this.setDetails()
  }

  async setDetails () {
    const { metadata, purchaseOrder, formData: { auto } } = this

    purchaseOrder.auto = auto

    this.metadata = {
      data: purchaseOrder,
      metadata,
    }

    this.showDetail = Boolean(purchaseOrder?.auto?.version?.id)
  }

  cleanFields () {
    this.formData.price = null
  }

  async getListValues () {
    this.acquisitionTypes = await this.fetchData({
      query: { name: 'find', model: 'AcquisitionType' },
      filter: { active: { _eq: true } },
    })

    if (this.acquisitionTypes.length === 1 || !this.displayConsignment) {
      this.formData.acquisitionType = this.acquisitionTypes[0]
    }

    const { metadata } = this.getForm('Lead', 'lead')
    const { fields } = metadata as Form
    this.fields.channel.properties = fields.channel?.properties
    this.fields.channel.items = await this.fetchData({
      query: { name: 'find', model: 'Channel' },
      filter: { ...fields.channel?.computed?.queries.items.where },
    })
  }

  async cancelPurchase () {
    await this.close()
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  // technicalReview | soap
  getInspectionOtherDocument (component, data, key) {
    return {
      idInspectionComponent: component.inspectionComponent.id,
      cost: parseToNumber(data?.cost),
      idFile: data?.photo?.[0]?.id,
      qualifications: [
        {
          idInspectionParameter: component.findQuestion(1)?.id,
          value: key !== 'technicalReview' ? data.selection?.alias : data.selection?.name,
        }, {
          idInspectionParameter: component.findQuestion(2)?.id,
          value: DateFormatter.toValidDBDate(data?.date),
        },
      ],
    }
  }

  getInspectionDocumentCirculation (component, data) {
    return {
      idInspectionComponent: component.inspectionComponent.id,
      cost: parseToNumber(data?.amount),
      idFile: data?.photo?.[0]?.id,
      qualifications: [
        {
          idInspectionParameter: component.findQuestion(1)?.id,
          value: data?.selection?.name,
        },
        {
          idInspectionParameter: component.findQuestion(2)?.id,
          value: DateFormatter.toValidDBDate(data?.date),
        },
        {
          idInspectionParameter: component.findQuestion(3)?.id,
          value: data?.amount,
        },
      ],
    }
  }

  setInspectionFields (formData) {
    return {
      components: [
        this.getInspectionOtherDocument(this.soap, formData.inspection.soap, 'soap'),
        this.getInspectionOtherDocument(this.technicalReview, formData.inspection.technicalReview, 'technicalReview'),
        this.getInspectionDocumentCirculation(this.circulationPermit, formData.inspection.circulationPermit),
      ],
    }
  }

  async buildExpenses (formData) {
    const expenses = await this.fetchData({
      query: { name: 'find', model: 'Expense' },
      filter: { type: { name: { _eq: 'auto_purchase' } } },
    })
    return formData?.expenses?.payments?.map(payment => {
      const idExpense = expenses.find(expense => expense.name === payment.type)?.id

      return {
        amount: parseToNumber(payment.amount),
        idPaymentType: payment.paymentType.id,
        idRecipientAccount: payment.account?.id,
        idExpense,
        comment: payment?.paymentRecipient?.payments?.[0]?.comment,
        idPerson: payment.person?.id,
      }
    })
  }

  async buildInfo (formData) {
    this.purchaseOrderDocumentType = await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'sale_purchase_contract' } }, { process: { id: { _eq: this.idProcessPurchase } } }] },
    })

    const inspectionFields = this.setInspectionFields(formData)
    const fields = this.purchaseOrderDocumentType[0].fields

    const interveners = await this.isSellerOrRepresentative(this, fields)
    let fixInterveners = null
    if (interveners?.length) {
      fixInterveners = interveners?.map(intervener => {
        return {
          idPerson: intervener?.id_person,
          businessDomicile: intervener?.business_domicilie,
          businessName: intervener?.business_name,
          idField: intervener?.id_field,
          idPersonAddress: intervener?.id_person_address,
          uid: intervener?.uid,
        }
      })
    }

    return {
      mileage: parseToNumber(formData.mileage),
      idPerson: formData.client?.id,
      idGeneration: formData.auto?.generation?.id,
      idVersionYear: formData?.auto?.version?.id,
      registrationPlate: formData.auto?.registrationPlate,
      idChannel: formData.channel?.id,
      idAcquisitionType: formData?.acquisitionType?.id,
      idSupportDocumentType: formData.documentType?.id,
      appraisal: {
        agreedAmount: parseToNumber(formData?.price),
      },
      idFilePowerOfAttorney: formData?.notarizedAuthorization?.[0]?.id,
      idFileLegalReport: formData.fileLegalReport?.[0]?.id,
      linkLegalReport: formData?.linkLegalReport,
      inspection: inspectionFields,
      negotiation: {
        idPenaltiesFile: formData.penalties?.[0]?.id,
        idCavFile: formData.cav?.[0]?.id,
      },
      interveners: fixInterveners,
      items: await this.buildExpenses(formData),
      supportDocuments: [
        {
          fileIds: formData.contract?.map(contract => contract.id),
          idSupportDocumentType: formData.documentType?.id,
          link: formData?.linkContract,
        },
      ],
    }
  }

  async confirmNegotiation () {
    this.negotiationAlert.flag = false
    this.negotiationAlert.title = ''
    this.negotiationAlert.subtitle = ''
    await this.send()
  }

  cancelNegotiationSend () {
    this.negotiationAlert.flag = false
    this.negotiationAlert.title = ''
    this.negotiationAlert.subtitle = ''
  }

  async send () {
    this.loadingForm = true
    const json = await this.buildInfo(this.formData)
    const resp = await this.createDirectPurchase(json)

    if (resp === null) {
      await this.loadNotification({
        // @ts-ignore catch must be any or unknown interface
        message: `Error creando la compra directa, Contacte a soporte tecnico`,
        notificationType: 'error',
      })
    } else if (this.formData.isRenewal) {
      const { stock, channel } = this.formData
      const id = resp?.response?.createdAppraisal?.appraisal?.deal?.lead?.id
      const discountTypes = await this.fetchData({
        query: { name: 'find', model: 'ItemType' },
        filter: {},
      })

      const statusSaleOrder = (await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'pending' } } }] },
      }))[0]

      const sale = {
        id_lead: id,
        id_auto: stock?.auto?.id,
        id_channel: channel?.id,
        id_process_status: this.statusDeal?.[0]?.id,
        id_stock: stock?.id,
        type: 'sale',
        sale_orders: {
          data: {
            sale_order_items: {
              data: [
                {
                  id_item_type: discountTypes?.find(item => item.name === 'product')?.id,
                  quantity: 1,
                  amount: stock?.price?.amount,
                },
              ].filter(_ => _.amount),
            },
            id_process_status: statusSaleOrder?.id,
          },
        },
      }

      await this.pushData({
        model: 'Deal',
        fields: sale,
      })
      const pipelines = await this.fetchData({
        query: { name: 'find', model: 'Pipeline' },
        filter: {},
      })
      await this.pushData({
        model: 'Lead',
        fields: {
          id,
          id_pipeline: pipelines?.find(pipeline => pipeline.name === 'renewal')?.id,
        },
      })
    }
    this.loadingForm = false
    await this.close()
  }

  @Watch('initializedComparison', { deep: true, immediate: true })
  async onCavChange (val) {
    if (!val?.cav?.[0]?.id || !val?.auto?.version?.id) return
    const fileInfo = await this.fetchData({
      query: { name: 'fetch', model: 'File', params: { id: val?.cav?.[0].id } },
      force: true,
    })

    const isSerialization = 'serialization' in fileInfo && fileInfo?.serialization
    const isCavInfo = 'cavInfo' in isSerialization && isSerialization?.cavInfo
    const carData = 'carData' in isCavInfo && isCavInfo.carData
    const currOwners = 'currOwners' in isCavInfo && isCavInfo.currOwners

    if (carData) {
      this.purchaseOrder.validations = carData.compareWithAuto(val.auto)
    }

    if (currOwners) {
      this.formData.owners = []
      // Asignar los propietarios procesados a formData.owners
      this.formData.owners = await Promise.all(
        currOwners.map(async (owner: CavCurrOwners) => {
          // Buscar si la persona ya existe
          const existingPersons = await this.findCavPerson(owner)

          if (existingPersons && existingPersons.length) {
            // Si existe, retornar la persona existente
            return existingPersons[0]
          } else {
            // Si no existe, crear una nueva persona
            return this.createPerson(owner)
          }
        })
      )
      this.fields.client.items = this.formData.owners
    }
  }

  async findCavPerson (owner) {
    return this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { uid: { _ilike: `%${owner.rutClean}%` } },
      force: true,
    })
  }

  async createPerson (owner) {
    const fields: any = {
      uid: owner.rutClean,
    }

    if (owner.isNaturalPerson) {
      // Agregar campos para personas naturales
      fields.first_name = owner.firstName
      fields.second_name = owner.secondName
      fields.surname = owner.surname
      fields.second_surname = owner.secondSurname
    } else if (owner.isCompany) {
      // Agregar campos para empresas
      fields.alias = owner.alias
      fields.company_name = owner.companyName
    }

    return this.pushData({
      model: 'Person',
      fields,
    })
  }

  get initializedComparison () {
    const { formData } = this

    return {
      auto: formData.auto,
      cav: formData.cav,
    }
  }

  get signers () {
    const { formData: { signers, owners, whoSigns } } = this

    if (whoSigns === false) {
      return signers?.length ? signers : []
    }

    if (!signers?.length) return owners || []

    return signers
  }

  findComponentBySlug (inspectionComponents, slug) {
    return inspectionComponents?.find(component => component.slug === slug)
  }

  async searchCity (val) {
    if (!val) return
    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: { name: { _ilike: `%${val}%` } },
    })
  }

  async searchCompany (val) {
    if (!val) return

    this.insurances = await this.fetchData({
      query: { name: 'find', model: 'Person', order: { alias: 'asc' } },
      filter: { company_type: { name: { _eq: `insurance_carrier` } }, alias: { _ilike: `%${val}%` } },
    })
  }

  async searchPlants (val) {
    if (!val) return

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { _and: [{ company_type: { name: { _eq: `technical_review_plant` } } }, { alias: { _ilike: `%${val}%` } }] },
      limit: 100,
    })
  }

  async searchPerson ({ input, type }) {
    if (!input?.length || input?.length < 2) return null

    const { metadata } = this
    if (!metadata) return null
    const { fields } = metadata.metadata as Form

    if (!fields?.client) return
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', this.replaceSpace(input), ['name_person_type'])

    const items = await this.findPersonSearch(query)

    if (type === 'client') {
      this.fields.client.items = items
    } else {
      this.fields.signers.items = items
    }
  }

  async goPerson (type) {
    this.saveBackup()

    const idPerson = type !== 'signer' ? this.formData[type]?.id : null
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Negotiation', uid: null, id: idPerson } })
  }

  async editPerson (person) {
    this.saveBackup()

    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Negotiation', uid: null, id: person.id } })
  }

  removePerson (person) {
    const { formData: { signers } } = this
    this.formData.signers = signers.filter(item => item.id !== person.id)
  }

  get bindClient () {
    const { fields: { client } } = this

    if (!client) return {}

    return { ...client.properties, items: client.items }
  }

  get bindOwners () {
    const { fields: { owners } } = this

    if (!owners) return {}

    return { ...owners.properties, items: owners.items }
  }

  get bindSigners () {
    const { fields: { signers } } = this

    if (!signers) return {}

    return { ...signers.properties, items: signers.items }
  }

  get generateDates () {
    return DateGenerator.generateDates(dayjs().year())
  }

  get generateSoapDates () {
    return DateGenerator.generateDates(dayjs().year(), false)
  }

  get isDigitalContract () {
    const { formData: { documentType }, documentFilesParameters } = this
    if (!documentType) return false

    const file = documentFilesParameters.find(file => file.name === `buyer_${documentType.name}` || file.name === documentType.name)

    if (file) {
      this.setProperties(documentFilesParameters, file.name, 'contract')
      this.fields.linkContract.properties.required = true
    } else {
      this.fields.contract.properties.name = null
    }

    return documentType?.name === 'digital_contract'
  }

  get isInvoice () {
    const { formData: { documentType } } = this
    const isInvoice = documentType?.name.includes('invoice')

    this.fields.linkContract.properties.required = !isInvoice

    return isInvoice
  }

  get ppu () {
    const { formData } = this
    if (!formData?.auto?.ppu) return undefined

    return formData.auto.ppu?.split('')[formData.auto.ppu.length - 1]
  }

  get technicalDates () {
    const {
      formData: {
        auto: {
          year,
        },
      },
      ppu,
    } = this

    if (!ppu) return []

    return DateGenerator.calculateMonthByDigit(parseToNumber(ppu), year)
  }

  get isTechnicalReviewMoreThanToday () {
    const { formData } = this
    if (!formData?.inspection?.technicalReview?.date) return false

    return dayjs(fixDate(DateFormatter.toValidDBDate(formData.inspection?.technicalReview.date)), 'DD/MM/YYYY').isAfter(dayjs())
  }

  get calculatedExpenses () {
    const { formData: { expenses } } = this

    return expenses?.payments || []
  }

  getValue (data, field) {
    return data?.[field]
  }

  @Watch('formData.auto', { deep: true, immediate: true })
  onAutoChange (val: Auto) {
    if (!val) return
    const { generation } = val

    const date = dayjs().year()
    const registration = generation?.registration?.find(registration => parseToNumber(registration.year) === parseToNumber(date))

    this.internalServiceAmount = registration?.permission
    if (!this.formData.inspection.circulationPermit.amount) {
      this.formData.inspection.circulationPermit.amount = registration?.permission
    }
    this.displayInternalServiceAmount = true
  }

  @Watch('expirationPeriodSoap', { immediate: true })
  onDateChange (val) {
    if (val >= 1) {
      this.formData.inspection.soap.cost = null
    } else {
      this.formData.inspection.soap.cost = this.soap?.inspectionComponent?.config?.cost
    }
  }

  @Watch('formData.price', { immediate: true })
  onPriceChange (val) {
    this.purchaseOrder.agreedAmount = val
  }

  @Watch('formData.signers', { immediate: true, deep: true })
  onSignerChange (val) {
    if (!val?.length) {
      this.signerError = 'El representante es obligatorio'
      this.formData.notarizedAuthorization = []
      return
    }

    const errors = []
    val?.forEach(signer => {
      if (!signer?.uid) {
        errors.push(`El representante ${signer.name} no tiene rut registrado`)
      }
    })

    this.signerError = errors.join(', ')
  }

  @Watch('formData.owners', { immediate: true, deep: true })
  onOwnersChange (val) {
    if (!val?.length) return

    this.validatePersonInfo(val, 'ownerError')
  }

  async validatePersonInfo (persons, field) {
    let error = false
    for (const person of persons) {
      const address = await this.fetchData({
        query: { name: 'find', model: 'PersonAddress' },
        filter: { _and: [{ id_person: { _eq: person.id } }, { active: { _eq: true } }] },
        force: true,
      })

      if (!person?.uid && !error) {
        error = true
        this[field] = `${person.fullName} no tiene un RUT asociado`
      }

      if (!address?.length && !error) {
        error = true
        this[field] = `${person.fullName} no tiene una dirección asociada`
      }
    }

    if (!error) {
      this[field] = ''
    }
  }

  displayExpense (isRenewal) {
    if (!this.formData.client?.id) return false

    if (isRenewal === null || (isRenewal === true && !this.formData?.stock?.id)) {
      return false
    } else if (isRenewal === false) {
      this.formData.stock = null
      return true
    }
    return true
  }

  get clientStockInfo () {
    const { formData } = this

    return {
      client: formData.client,
      stock: formData.stock,
    }
  }

  @Watch('clientStockInfo', { immediate: true, deep: true })
  async stockChange (val) {
    if (!val?.client?.id || !val?.stock?.id) return

    const resp = await this.getOpenLead({ autoId: val.stock.auto.id, clientId: val.client.id })
    const leadInfo = plainToInstance(Lead, resp?.[0])

    if (leadInfo) {
      const text = leadInfo.executive.id === this.idEmployee
        ? `Este cliente ya tiene un negocio en progreso con ese stock, lead: Nº ${leadInfo.id}`
        : `Este cliente ya tiene un negocio en progreso con ese stock, lead: Nº ${leadInfo.id} con el ejecutivo ${leadInfo.executive?.person?.firstName} ${leadInfo.executive.person.surname}`
      sendNotification(text, 'error')
      this.formData.stock = null
      this.disableSend = true
    }
  }
  }
