import { render, staticRenderFns } from "./PurchaseStockForm.vue?vue&type=template&id=60d7a7c8&scoped=true&"
import script from "./PurchaseStockForm.vue?vue&type=script&lang=ts&"
export * from "./PurchaseStockForm.vue?vue&type=script&lang=ts&"
import style0 from "./PurchaseStockForm.vue?vue&type=style&index=0&id=60d7a7c8&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d7a7c8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAutocomplete,VBtn,VChip,VCombobox,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VForm,VIcon,VRow,VTextField,VTooltip})
